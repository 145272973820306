import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ImageWithConfigFragment } from '@codegen/cmsUtils';
import { useQuery } from '@tanstack/react-query';
import { IconColourProp, ThemeResponsiveProp } from '@ui-v2/types/props';
import { buildResponsiveValues } from '@ui-v2/utils/buildResponsiveValues';
import { retrieveThemeValues } from '@ui-v2/utils/retrieveThemeValues';
import { createCSSFilterFromHex } from '@ui-v2/utils/styleUtils';

const DEFAULT_ICON_SIZE = 12;

export type IconProps = {
  className?: string;
  colour?: IconColourProp;
  height?: ThemeResponsiveProp<number>;
  icon: ImageWithConfigFragment | null;
  size?: ThemeResponsiveProp<number>;
  width?: ThemeResponsiveProp<number>;
};

const Container = styled.div<
  Omit<IconProps, 'height' | 'width' | 'color' | 'icon' | 'src'> & {
    boundaryHeight: ThemeResponsiveProp<number>;
    boundaryWidth: ThemeResponsiveProp<number>;
  }
>(
  ({
    boundaryHeight,
    boundaryWidth,
    colour = 'icons.default',

    theme,
  }) => [
    css`
      & > svg {
        ${buildResponsiveValues({
          width: boundaryWidth,
          height: boundaryHeight,
        })}
        ${createCSSFilterFromHex(
          retrieveThemeValues(theme.colours, colour) as unknown as string,
        )};
      }
    `,
  ],
);

const fetchSVG = async (url?: string) => {
  if (!url) {
    return null;
  }

  const response = await fetch(url);
  const data = await response.text();

  return data.match(/fill="none"/)
    ? data
    : data
        .replace(/fill=".*?"/g, 'fill="#000"')
        .replace(/stroke=".*?"/g, 'stroke="#000"');
};

const Icon = ({ height, icon, size, width, ...props }: IconProps) => {
  const definedWidth = width ?? size ?? DEFAULT_ICON_SIZE;
  const definedHeight = height ?? size ?? DEFAULT_ICON_SIZE;

  const { data: svgData } = useQuery({
    queryKey: [icon?.asset.url] as string[],
    queryFn: () => fetchSVG(icon?.asset.url),
    enabled: !!icon?.asset.url,
  });

  if (!icon) {
    // eslint-disable-next-line no-console
    console.warn('Icon not found in Icon.tsx');

    return null;
  }

  if (!svgData) {
    return null;
  }

  return (
    <Container
      boundaryHeight={definedHeight}
      boundaryWidth={definedWidth}
      dangerouslySetInnerHTML={{ __html: svgData }}
      {...props}
    />
  );
};

export default Icon;
